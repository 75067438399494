import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Ticker from 'react-ticker';
import constants from '../../constants';
import Img from 'gatsby-image'
import MediaWithCaption from '../media-with-caption';
import Diptyque from "components/diptyque"
import SendToPageCTA from "components/send-to-page-cta"
import InlineVideo from '../inline-video';

const Aw21DropBrand = () => {
  const overallPadding = constants.landingOverallPadding
  const textColor = `text-black`
  const Grid = ({ children, className }) => (
    <div className={`grid grid-cols-12 ${className}`}>{children}</div>
  )
  const data = useStaticQuery(graphql`
    fragment fluidImage on File { childImageSharp { fluid(maxWidth: 1980, quality:80, ) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
    query {
      layingWithBags2: file(relativePath: { eq: "images/aw21-drop-brand/ECCO_210429_TCRISTIANI_120_1604_V2_FINAL.jpg" }) { ...fluidImage }
      hugeHandBag: file(relativePath: { eq: "images/aw21-drop-brand/huge-hand-bag.jpg" }) { ...fluidImage }
      yellowShirtSmiling: file(relativePath: { eq: "images/aw21-drop-brand/yellow-shirt-smiling.jpg" }) { ...fluidImage }
      huggingBags: file(relativePath: { eq: "images/aw21-drop-brand/hugging-bags.jpg" }) { ...fluidImage }
      layingWithBags: file(relativePath: { eq: "images/aw21-drop-brand/layin-with-bags_2.jpg" }) { ...fluidImage }
      grayPattern: file(relativePath: { eq: "images/aw21-drop-brand/Ecco_Wave_Gray_4.jpg" }) { ...fluidImage }
      sideLooking: file(relativePath: { eq: "images/aw21-drop-brand/yellow-side-looking.jpg" }) { ...fluidImage }
      threePeople: file(relativePath: { eq: "images/aw21-drop-brand/three-people.jpg" }) { ...fluidImage }
      womanHoldingBag: file(relativePath: { eq: "images/aw21-drop-brand/woman-holding-bag.png" }) { ...fluidImage }
      belts: file(relativePath: { eq: "images/aw21-drop-brand/belts.jpg" }) { ...fluidImage }
      diptyque: file(relativePath: { eq: "images/core/Sofia - Baseline - Universal - crop.jpg" }) { ...fluidImage }
    }
  `)
  const indexItems = [
    `ECCO Padded Hobo in ECCO Blue`,
    `ECCO Oversized Clutch in Black, Creme Fraiche and ECCO Blue`,
    `ECCO Padded Crossbody in Aubergine, ECCO Blue and Black`,
    `ECCO Fold Shopper in ECCO Blue`,
    `ECCO Padded Hobo in ECCO Blue`,
    `ECCO Padded Hobo in ECCO Blue, Black and Aubergine`,
    `ECCO Padded Crossbody in Aubergine, ECCO Blue and Black`,
    `ECCO Oversized Clutch in Black`,
    `ECCO Oversized Clutch in Black, Creme Fraiche and ECCO Blue`,
    `ECCO Oversized Clutch in Black`,
    `ECCO Oversized Clutch in ECCO Blue with assorted ECCO Alphabet Leather Charms`
  ]
  return (
    <div className={`w-full bg-white`}>
      <div className={`bg-brand-gray`}>
        <Ticker
          speed={10}
        >
          {() => (
            <p className={`bg-brand-gray text-black uppercase flex items-center pt-2 pb-2 lg:pt-2 lg:pb-4 ${constants.h2} whitespace-nowrap`}>
              &nbsp;&#8226;&nbsp;FUNCTIONAL, OPTIMISTIC, + INNOVATIVE LEATHER GOODS
            </p>
          )}
        </Ticker>
      </div>
      <Grid className={`pt-10 pb-0 lg:pt-20 lg:pb-10`}>
        <p className={`${textColor} ${constants.quote} ${overallPadding} col-start-2 col-end-12 lg:col-start-1 lg:col-end-11`}>
          <span>PADDED COLLECTION</span><br/>
          Crafted from ultra soft leather with plush padding, this collection is made to move with you. Cloudlike leather construction meets hands free design for a modern, active lifestyle.
        </p>
      </Grid>
      <Grid className={`lg:pl-7 mt-10`}>
        <div className={`col-start-2 col-end-13 lg:col-start-1 lg:col-end-7 mb-16 lg:mb-0 relative z-10`}>
          <div className={`relative`}>
            <MediaWithCaption
              image={data.layingWithBags2}
              className={``}
              captionClassName=''
            >
              <span className={`${constants.caption} pt-2 pr-2 lg:hidden`}>
                03&#x00029;
              </span>
              <span>Pillow soft and crafted for comfort, Anania lounges with the Padded Crossbody bag.</span>
            </MediaWithCaption>
            <div className={`${constants.caption} pt-2 hidden lg:block absolute bottom-60 right-0 transform -mr-3 translate-x-full`}>03&#x00029;</div>
          </div>
        </div>
        <div className={`col-span-11 lg:col-span-6`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full lg:pt-80`}>
            <div className={`lg:ml-auto w-full lg:pt-96 lg:w-110% lg:-left-10% relative`}>
              <MediaWithCaption
                image={data.hugeHandBag}
                captionClassName={`pl-2 lg:pl-0`}
              >
                04&#x00029;
              </MediaWithCaption>
            </div>
          </div>
        </div>
      </Grid>
      <p className={`${constants.paragraph} text-center w-8/12 my-8 mx-auto lg:w-5/12 lg:my-0 lg:mt-32`}>
        Heritage designs reimagined in unusual shapes for a new generation of trailblazers. Soft, thin leather blends with quality craftsmanship and expert attention to detail.
      </p>
      <Grid className={`pt-4 lg:pt-0 lg:pl-7 lg:mt-32`}>
        <div className={`col-start-2 col-end-13 lg:col-start-1 lg:col-end-7 mb-8 lg:mb-0`}>
          <MediaWithCaption
            image={data.yellowShirtSmiling}
            className='relative'
            captionClassName={`pr-2 lg:pr-0 w-full text-right`}
          >
            05&#x00029;
          </MediaWithCaption>
        </div>
        <div className={`col-start-1 col-end-13 lg:col-start-7 lg:col-end-13`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full lg:pt-60`}>
            <div className={`lg:mx-auto w-full lg:pt-96 ${overallPadding}`}>
              <InlineVideo
                src="CROSSBODY.mp4"
              />
              <div className={`flex space-x-2 ${constants.caption} pt-2 px-2 lg:px-0`}>
                <div>07&#x00029;</div>
                <div>Contemporary design made for modern living, Anania is ready for the future ahead with the Padded Crossbody bag.</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`col-start-3 col-end-13 lg:col-start-1 lg:col-end-6 mt-16 lg:mt-0`}>
          <MediaWithCaption
            image={data.huggingBags}
            className='relative'
            captionClassName='absolute right-0 top-0 transform -translate-y-full pb-2 pr-2 lg:pr-0'
          >
            06&#x00029;
          </MediaWithCaption>
        </div>
        <div className={`col-start-1 col-end-13 lg:col-start-7 lg:col-end-13 ${overallPadding}`}>
          <div className={`w-full h-full flex items-end`}>
            <p className={`${constants.paragraph} w-8/12 my-8 lg:my-0 mx-auto lg:mx-0 text-center lg:text-left lg:w-8/12 lg:pb-32`}>
              A soft, leather pad on the adjustable shoulder strap adds maximum comfort for hands-free wear no matter where the day takes you.
            </p>
          </div>
        </div>
      </Grid>
      <Img
        fluid={data.grayPattern.childImageSharp.fluid}
        className=''
      />
      <p className={`${textColor} ${constants.quote} ${overallPadding} px-7 lg:pr-10 pt-8 pb-6 lg:pt-20 lg:pb-20 lg:w-10/12`}>
        FOLDED COLLECTION — Supple, sturdy leather creates unique silhouettes using ECCO’s Ultra Soft Leather technique. This collection embraces handsfree construction with groundbreaking, multifunctional designs for purpose driven living. 
      </p>
      <Grid className={`${overallPadding} mt-4 lg:mt-10`}>
        <div className={`col-start-1 col-end-10 lg:col-start-1 lg:col-end-9 mb-10 lg:mb-0`}>
          <MediaWithCaption
            image={data.sideLooking}
            className='relative z-10'
            captionClassName='absolute top-0 right-0 transform -mr-3 translate-x-full pt-0'
          >
            08&#x00029;
          </MediaWithCaption>
        </div>
        <div className={`col-start-3 col-end-12 lg:col-start-9 lg:col-end-13 ${overallPadding}`}>
          <div className={`flex items-end h-full`}>
            <p className={`${textColor} ${constants.paragraph}`}>
              Butter soft yet structured leather designed to maximize function with the perfect volume for daily necessities.
            </p>
          </div>
        </div>
      </Grid>
      <Grid className={`${overallPadding} mt-10 lg:mt-40`}>
        <div className={`lg:hidden col-start-1 col-end-13 ${constants.paragraph} text-center mt-6 mb-4`}>
          <div className={`w-8/12 mx-auto`}>Multifunctional design offers many ways to wear on the go.</div>
        </div>
        <div className={`mt-12 col-start-1 col-end-13 lg:mt-0 lg:col-start-1 lg:col-end-7 pb-8 lg:pb-0 `}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full lg:pt-96`}>
            <div className={`lg:mx-auto w-full lg:pr-7`}>
              <InlineVideo
                src="OVERSIZE.mp4"
                videoStyle={{objectFit: 'cover'}}
              />
              <div className={`flex space-x-2 ${constants.caption} pt-2 pl-2 lg:pl-0`}>
                <div>10&#x00029;</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row-start-1 col-start-1 col-end-13 lg:col-start-7 lg:col-end-13  mb-5 lg:mb-0`}>
          <MediaWithCaption
            image={data.threePeople}
            className='relative z-10 w-10/12 float-right lg:float-none lg:w-full'
          >
            09&#x00029;
          </MediaWithCaption>
          <p className={`hidden lg:block w-2/3 mt-12 -ml-20 lg:w-1/2 text-center lg:mx-auto lg:mt-40 ${constants.paragraph}`}>
            Multifunctional design offers many ways to wear on the go.
          </p>
        </div>
        <div className={`lg:hidden col-start-2 col-end-13 lg:col-start-6 lg:col-end-13 relative mt-8`}>
          <MediaWithCaption
            image={data.belts}
            className=''
            captionClassName={`lg:absolute lg:top-0 lg:left-0 lg:z-10 lg:transform lg:-translate-x-full lg:-ml-3`}
          >
            11&#x00029;
          </MediaWithCaption>
        </div>
      </Grid>
      <Grid className={`${overallPadding} mt-10 mb-10 lg:mt-40 lg:mb-40`}>
        <div className={`col-start-2 col-end-12 lg:col-start-1 lg:col-end-6 relative`}>
          <div className={`flex h-full items-end`}>
            <div className={`w-full lg:w-7/12`}>
              <div className={`${constants.paragraph} pb-9`}>—Index</div>
              {
                indexItems.map((item, index) => {
                  return (
                    <div key={`paragraph-${index}`} className={`mb-4 ${constants.paragraph}`}>{`${index+1}. ${item}`}</div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className={`hidden lg:block col-start-2 col-end-12 lg:col-start-6 lg:col-end-13 relative`}>
          <MediaWithCaption
            image={data.belts}
            className=''
            captionClassName={`absolute top-0 left-0 z-10 transform -translate-x-full -ml-3`}
          >
            11&#x00029;
          </MediaWithCaption>
        </div>
      </Grid>
      <Diptyque
        props={{
          title: `Enter a Brave New World`,
          paragraph: `Optimistic, gender‑free, designed for New Utopians.`,
          color: `#BC9CDB`,
          borderColor: `border-black`,
          textColor: `text-black`,
          image: data.diptyque,
          button: {
            to: `/catalogue?collection=brand`,
            text: `Discover the SS/21 campaign`
          }
        }}
      />
      <SendToPageCTA
        title={`Discover the Brand Collection`}
        button={{
          to: `/catalogue`,
          title: `View All Products`
        }}
      />
    </div>);
}

export default Aw21DropBrand;