import React, {Component} from "react"
import Layout from "components/layout"
import {graphql, navigate} from "gatsby"
import SEO from "components/seo"
import MovingGrid from "components/moving-grid"
import MovingGridMenu from "components/moving-grid-menu"
import constants from "../../constants"
import Brand from "components/landing-aw21-drop-brand"
import Alphabet from "components/landing-aw21-drop-alphabet"
import Actions from "../../actions"
import Store from "../../store"
import animateScrollTo from "animated-scroll-to"
import Sticky from "react-stickynode"

class AW21DropLanding extends Component {
  constructor(props) {
    super(props)
    this.alphabetGrid = React.createRef()
    this.brandGrid = React.createRef()
    this.cnyGrid = React.createRef()
    this.state = {currentId: undefined, viewType: undefined}
  }
  componentDidMount() {
    Store.on(constants.CHANGE_COLLECTION_AW21_DROP, this.onChangeCollection)
    window.addEventListener("mousemove", this.onMouseMove, false)
    setTimeout(() => {
      this.setState({currentId: Store.currentLandingIdAW21Drop || constants.AW21_DROP_BRAND})
    }, 200)
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    Store.off(constants.CHANGE_COLLECTION_AW21_DROP, this.onChangeCollection)
    window.removeEventListener("mousemove", this.onMouseMove, false)
  }
  getViewType = () => {
    let type = constants.DESKTOP
    if (window.innerWidth < constants.MQ_MEDIUM) type = constants.MOBILE
    else if (window.innerWidth < constants.MQ_LARGE) type = constants.TABLET
    return type
  }
  resize = () => {
    const viewType = this.getViewType()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
  }
  onMouseMove = (e) => {
    const isDesktop = this.state.viewType === constants.DESKTOP
    if (!isDesktop) return
    const mouse = {clientX: 0, clientY: 0}
    if (e.touches && e.touches.length > 0) {
      mouse.clientX = e.touches[0].clientX || mouse.clientX
      mouse.clientY = e.touches[0].clientY || mouse.clientY
    } else {
      mouse.clientX = e.clientX || mouse.clientX
      mouse.clientY = e.clientY || mouse.clientY
    }
    if (this.state.currentId === constants.AW21_DROP_ALPHABET) this.alphabetGrid.current.onMouseMove(mouse)
    if (this.state.currentId === constants.AW21_DROP_BRAND) this.brandGrid.current.onMouseMove(mouse)
  }
  onMenuClicked = (id) => {
    animateScrollTo(0, {speed: 600, easing: constants.scrollingToEasing}).then(() => {
      setTimeout(Actions.changeCollectionAw21Drop, 0, {id, type: constants.AW21_DROP})
    })
  }
  onChangeCollection = ({id}) => {
    // navigate(`/lookbook/aw21-drop?id=${id}`)
    this.setState({currentId: id})
  }
  getLandingPage = () => {
    if (this.state.currentId === constants.AW21_DROP_ALPHABET) {
      return Alphabet
    } else {
      return Brand
    }
  }
  render() {
    const data = this.props.data.allAw21DropLandingJson.nodes
    const alphabetData = data.filter(item => item.id === constants.AW21_DROP_ALPHABET)[0]
    const brandData = data.filter(item => item.id === constants.AW21_DROP_BRAND)[0]
    const Page = this.getLandingPage()
    const sharedStyles = `absolute top-0 left-0`
    return (
      <Layout>
        <SEO
          title={`Connect to Ecco`}
          pathname={this.props.location.pathname}
        />
        <div className={`w-full h-80vh lg:h-screen overflow-hidden`}>
          <div className={`uppercase w-full h-full relative transition-colors ease-linear duration-500 ${this.state.currentId !== constants.AW21_DROP_ALPHABET ? `bg-white` : `bg-menu-gray`}`}>
            <MovingGrid ref={this.alphabetGrid} viewType={this.state.viewType} className={`${sharedStyles} ${this.state.currentId === constants.AW21_DROP_ALPHABET ? `z-10` : `z-0`}`} color={`dark`} active={this.state.currentId === constants.AW21_DROP_ALPHABET} id={constants.AW21_DROP_ALPHABET} data={alphabetData} />
            <MovingGrid ref={this.brandGrid} viewType={this.state.viewType} className={`${sharedStyles} ${this.state.currentId === constants.AW21_DROP_BRAND ? `z-10` : `z-0`}`} color={`light`} active={this.state.currentId === constants.AW21_DROP_BRAND} id={constants.AW21_DROP_BRAND} data={brandData} />
          </div>
          <Sticky enabled={true} top={100} innerZ={20}>
            <MovingGridMenu
              colors={this.state.currentId === constants.AW21_DROP_BRAND ? 'bg-menu-gray text-black' : 'bg-black text-white'}
              onClick={this.onMenuClicked}
              currentId={this.state.currentId}
              className={`absolute left-1/2 transform -translate-x-1/2 z-20 bottom-6`}
              options={[
                {label: 'Alphabet', id: constants.AW21_DROP_ALPHABET},
                {label: 'Brand', id: constants.AW21_DROP_BRAND}
              ]}
            />
          </Sticky>
        </div>
        <div className={`w-full h-full`}>
          <Page />
        </div>
      </Layout>
    )
  }
}

export default AW21DropLanding

export const data = graphql`
  query aw21DropLandingQuery {
    allAw21DropLandingJson {
      nodes {
        id
        moving_grid {
          titleA
          titleB
          subtitle
          grid {
            caption {
              content
              className
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            className
            video
            styles {
              width
              bottom
              right
              top
              left
              zIndex
            }
            stylesMobile {
              width
              bottom
              right
              top
              left
            }
          }
        }
      }
    }
  }
`

