import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import Ticker from 'react-ticker';
import constants from '../../constants';
import MediaWithCaption from '../media-with-caption';
import Diptyque from "components/diptyque"
import Img from "gatsby-image"
import SendToPageCTA from "components/send-to-page-cta"
import InlineVideo from '../inline-video';

const Aw21DropAlphabet = () => {
  const overallPadding = constants.landingOverallPadding
  const textColor = `text-white`
  const Grid = ({children, className}) => (
    <div className={`grid grid-cols-12 ${className}`}>{children}</div>
  )
  const data = useStaticQuery(graphql`
    fragment fluidImage on File { childImageSharp { fluid(maxWidth: 1980, quality:80, ) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
    query {
      tImage: file(relativePath: { eq: "images/aw21-drop-alphabet/ECCO_210429_TCRISTIANI_090_1382_V4_FINAL.jpg" }) { ...fluidImage }
      tLetterImage: file(relativePath: { eq: "images/aw21-drop-alphabet/Ecco_T_Image_Horizontal.png" }) { ...fluidImage }
      eImage: file(relativePath: { eq: "images/aw21-drop-alphabet/ECCO_210429_TCRISTIANI_060_1070_V3_FINAL.jpg" }) { ...fluidImage }
      eLetterImage: file(relativePath: { eq: "images/aw21-drop-alphabet/Ecco_E_Letter_Horizontal 1.png" }) { ...fluidImage }
      brave: file(relativePath: { eq: "images/aw21-drop-alphabet/Ecco_Brave_Vertikal.png" }) { ...fluidImage }
      smilingWoman: file(relativePath: { eq: "images/aw21-drop-alphabet/woman-smiling.jpg" }) { ...fluidImage }
      diptyque: file(relativePath: { eq: "images/core/Sofia - Baseline - Universal - crop.jpg" }) { ...fluidImage }
    }
  `)
  return (
    <div className={`w-full bg-menu-gray`}>
      <div className={`bg-black`}>
        <Ticker
          speed={10}
        >
          {() => (
            <p className={`bg-black text-white uppercase flex items-center pt-2 pb-2 lg:pt-2 lg:pb-4 ${constants.h2} whitespace-nowrap`}>
              &nbsp;&#8226;&nbsp;WHIMSICAL, CREATIVE, PERSONAL
            </p>
          )}
        </Ticker>
      </div>
      <p className={`${textColor} ${constants.quote} ${overallPadding} px-7 pr-10 pt-10 pb-5 lg:pt-20 lg:pb-10 lg:w-3/4`}>
        Inspired by our ECCO “E” logo, plush, padded leather blends with playful design. This new set of miniature charms add a finishing touch to your favorite ECCO Leather Goods.
      </p>
      <Grid className={`mt-8 lg:mt-10`}>
        <div className={`col-start-1 col-end-12 lg:col-start-2 lg:col-end-9 lg:mb-0 lg:relative lg:pt-20`}>
          <div className={`relative w-full`}>
            <Img
              fluid={data.tImage.childImageSharp.fluid}
              className={``}
            />
            {/* bottom-bT right-rT  */}
            <div style={{bottom: `4.8%`, right: `27.3%`, width: `31.1%`}} className={`absolute z-10`}>
              <Img
                fluid={data.tLetterImage.childImageSharp.fluid}
                className={`transform translate-y-full translate-x-full`}
              />
            </div>
          </div>
          <p className={`pl-8 lg:pl-0 pt-8 ${constants.paragraph} lg:pt-10 w-10/12 lg:w-7/12 ${textColor}`}>
            Embrace individuality and style your way — mix and match ECCO Alphabet Charms to create a custom look or share as a personalized gift.
          </p>
        </div>
        <div className={`col-start-1 col-end-4 lg:col-start-9 lg:col-end-13 mt-0 lg:mt-0 mb-4 lg:mb-0 lg:h-1/2`}>
          <div className={`lg:flex lg:flex-col lg:justify-start lg:h-full`}>
            <div className={`w-full pl-12 lg:pl-0 lg:ml-auto lg:-mt-48 lg:h-full`}>
              <MediaWithCaption
                image={data.brave}
                imageClassName='w-full mr-auto lg:w-3/12 lg:mx-auto'
                objectFit='contain'
              />
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`${overallPadding} lg:mt-10`}>
        <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-8 mb-16 lg:pr-16 lg:mb-0 lg:relative lg:pt-40`}>
          <div className={`relative`}>
            <InlineVideo
              src="UTOPIA.mp4"
            />
            <p className={`${constants.caption} absolute pl-4 pb-4 left-0 bottom-0 w-full ${textColor}`}>
              Wanyun spells out what the future holds using Alphabet Leather Charms.
            </p>
          </div>
        </div>
        <div className={`col-start-1 col-end-13 lg:self-end lg:col-start-8 lg:col-end-13 mb-5 lg:mb-0`}>
          <p className={`w-8/12 mx-auto text-center lg:text-left lg:ml-auto lg:mt-auto ${constants.paragraph} ${overallPadding} ${textColor} lg:w-9/12 lg:mr-auto`}>
            Whimsical style meets expert craftsmanship, the exquisite Pegasus and Pumice leathers have a natural sheen and eye-catching contrast.
          </p>
        </div>
      </Grid>
      <Grid className={`${overallPadding} mt-10 lg:mt-6`}>
        <div className={`col-start-2 col-end-13 lg:col-start-6 lg:col-end-13 lg:mb-0 lg:relative lg:pt-20`}>
          <div className={`relative w-full`}>
            <div>
              <p className={`pl-4 pt-4 lg:pl-4 lg:pt-4 ${constants.caption} text-black absolute z-10`}>
                Mesmerized by modern colors, Yuuki marvels the unlimited ways to style ECCO Alphabet Leather Charms.
              </p>
              <Img
                fluid={data.eImage.childImageSharp.fluid}
                className={``}
              />
            </div>
            {/* bottom-bT right-rT  */}
            <div style={{bottom: `4.9%`, left: `0.1%`, width: `22%`}} className={`absolute z-10`}>
              <Img
                fluid={data.eLetterImage.childImageSharp.fluid}
                className={`transform translate-y-full`}
              />
            </div>
          </div>
          <div className={`w-full flex justify-end`}>
            <p className={`pl-2 lg:pl-0 ${constants.paragraph} lg:pt-10 w-9/12 mt-8 lg:mt-0 pr-6 lg:pr-0 lg:w-7/12 ${textColor}`}>
              Letters A through Z available in six spirited colorways — Black, Electric Blue, Green Pine, Lilac Marble, Marigold, or Wet Almond.
            </p>
          </div>
        </div>
      </Grid>
      <div className={`h-16 lg:h-40vh w-full block`}></div>
      <Diptyque
        props={{
          title: `Enter a Brave New World`,
          paragraph: `Optimistic, gender‑free, designed for New Utopians.`,
          color: `#BC9CDB`,
          borderColor: `border-black`,
          textColor: `text-black`,
          image: data.diptyque,
          button: {
            to: `/catalogue?collection=core`,
            text: `Discover the AW/21 campaign`
          }
        }}
      />
      <SendToPageCTA
        title={`Discover the Core Collection`}
        button={{
          to: `/catalogue`,
          title: `View All Products`
        }}
      />
    </div>
  );
}

export default Aw21DropAlphabet;